import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join"; 
import api from "../constants/api";
import storageKeys from "../constants/storageKeys";
import { recuperar } from "../helpers/local_storage";
import { logoutFromLocal } from "../helpers/logout";

export class Residuos {
    static async list() {
        const url = urlJoin(api.host, api.base, api.residuos); 
        const token = recuperar(storageKeys.user.token);
        console.log("Token recuperado:", token);
        if (!token) {
            console.warn("No hay token, redirigiendo al login...");
            logoutFromLocal(); 
            window.location.href = "/";
            return false;
        }
        try{ 
            
            let response = await axios.get(url, { headers: {
                //"User-Agent": api.userAgent,
                "Authorization": "Bearer " + recuperar(storageKeys.user.token)
            }});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            console.log('Devolvemos los datos', response.data);
            return response.data; 
             
        } catch(error){
            if(error.response && error.response.status){
                console.log(error);
                console.warn("Sesión expirada, cerrando sesión...");
                logoutFromLocal();
                window.location.href = "/";
                return false;
                //return error.response.status;
            }
            console.log('Error en la petición', error);
            return false;
        }  
    }
}