import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './VistaGenerador.scss';
import Menu from '../Menu/Menu';   
import GeneradorForm from '../Generador/Generador';
import EditarGeneradorForm from '../EditarGenerador/EditarGenerador';
import { Etiquetas } from '../../requests/etiquetas';
import { recuperar } from '../../helpers/local_storage';
import storageKeys from '../../constants/storageKeys';
import { Logger } from '@kcram-solutions/logger';
import { checkLastLogin, logoutFromLocal } from '../../helpers/logout'; 

export default function VistaGeneradorForm() { 
  const logger = new Logger('Editar-generador')
  const { id } = useParams();
  const [data, setData] = useState('');
  const navigate = useNavigate();

  logger.log("objeto: ",id)
  
  useEffect(() => {
    const valores = recuperar(storageKeys.user.data); 
    if (!valores || checkLastLogin()) {
      logoutFromLocal();
      navigate("/");
      return
    }
    if(id){
      logger.log('Etiqueta', id);
      // let etiqueta = recuperar(storageKeys.etiquetas.selected);
      // if(etiqueta){
      //   setData(etiqueta)
      // }else
      Etiquetas.getOne(id).then(data =>{
        logger.log('Encontrada etiqueta', data);
        setData(data);
      })
    }
  }, []);

  if (id) {
    return (
      <div className='generador'>
        <div className='wrapper'>
          <Menu index={0} />
          <EditarGeneradorForm data={data} />
        </div>
      </div>
    );
  } else {
    return (
      <div className='generador'>
        <div className="wrapper">
          <Menu index={0} />
          <GeneradorForm />
        </div>
      </div>
    )
  }

  /*return (
    <div>
      <h1>El valor del parámetro es: {id}</h1>
    </div>
  );*/

}
