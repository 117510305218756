import React, { useEffect, useState } from 'react';
import './Vista.css';
import Menu from '../Menu/Menu';
import ElementoLista from '../ElementoLista/ElementoLista';
import { Pagination, Spinner } from 'react-bootstrap';
import { Etiquetas } from '../../requests/etiquetas';
import { useNavigate } from 'react-router-dom';
import { recuperar } from '../../helpers/local_storage';
import storageKeys from '../../constants/storageKeys';
import { Logger } from '@kcram-solutions/logger';
import { checkLastLogin, logoutFromLocal } from '../../helpers/logout'; 


const logger = new Logger('Vista');

export default function VistaForm() {

  const [imagen, setImagen] = useState('');
  const [c_ler, setC_Ler] = useState('');
  const [n_residuo, setN_Residuo] = useState('');
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState('');
  const [pagination, setPagination] = useState('');
  const [noData, setNoData] = useState(false);
  const [disablePrev, setDisablePrev] = useState('disabled')
  const [disableNext, setDisableNext] = useState('')
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const navigate = useNavigate();

  function handleImagenChange(e) {
    const { name, value } = e.target
    setImagen(value)
  }

  function handleC_LerChange(e) {
    const { name, value } = e.target
    setC_Ler(value)
  }

  function handleN_ResiduoChange(e) {
    const { name, value } = e.target
    setN_Residuo(value)
  }

  function handleButtonSubmit(e) {
    e.preventDefault()
    alert(c_ler)
  }

  useEffect(() => {
    const valores = recuperar(storageKeys.user.data);
    if (!valores || checkLastLogin()) {
      logoutFromLocal();
      navigate("/")
      return
    }
    Etiquetas.list().then(data =>{
      if (!data) {
        setNoData(true);
      } else {
        logger.log('paginación', data.pagination)
        setPagination(data.pagination);
        setElements(data.etiquetas);
        logger.debug('Páginas', data.pagination.pageCount);
        loadItemsPage(data.pagination.pageCount)
      }
      setLoading(false);
    });
  }, [])

  async function loadEtiquetas(page = 1) {
    if(pagination && pagination.pageCount && page > pagination.pageCount-1){
      logger.warn('No existe página');
      setDisableNext('disabled')
        page = pagination.pageCount;
      }
      if(page === pagination.pageCount){
        setDisableNext('disabled');
      }else{
        setDisableNext('');
      }
      if(page === 1 || page < 1){
        page = 1;
        setDisablePrev('disabled')
      }else{
      setDisablePrev('')
      }
      logger.info('Selección', page);
    setSelectedPage(page);
    const data = await Etiquetas.list(page)
    console.log(data);
    if (!data) {
      setNoData(true);
    } else {
      logger.log('paginación', data.pagination)
      setPagination(data.pagination);
      setElements(data.etiquetas);
    }
    setLoading(false);
  }
  function loadItemsPage(end, start=1){
    if (start > end) return;
    if(pages.length > end) return;
    pages.push(start);
    logger.debug('Arr:', pages);
    logger.debug('Iteración', start);
    loadItemsPage(end, start + 1);
  }
  function nextEtiquetas(){
    changeElements()
    const next = pagination.page + 1;
    logger.info('Next:', next);
    loadEtiquetas(next);
  }
  function goFirts(page=1){
    changeElements()
    logger.info('Primera',page);
    loadEtiquetas(page);
  }

  function goEnd(page = pagination.pageCount){
    changeElements()
    logger.info('Ultima', page);
    loadEtiquetas(page);
  }

  function getFirst(){
    const start = pagination.page - 4;
    return start >= 0 ?? 0;
  }

  function prevEtiquetas(){
    changeElements()
    let next = pagination.page - 1;
    logger.info('Next:', next);
    if(next < 1){
      next = 1
    };
    loadEtiquetas(next);
  }


  useEffect(() => {
    
  }, [loading])

  function changeElements() {
    setLoading(true);
    loadEtiquetas(pagination.page)
  }
  if (loading) {
    return (
      <div className="wrapper">
        <Menu index={2} />
        <center>
          <Spinner animation='grow' className='mt-3' />
        </center>
      </div>
    )
  } else if (noData) {
    return (
      <div className="wrapper">
        <Menu index={2} />
        <center>
          <div className="NoData">No hay datos</div>
        </center>
      </div>
    )
  } else {
    return (
      <div className='wrapper'>
        <Menu index={2} />
        <div className='div_principal_etiquetas'>
          <h2 className='titulo_etiquetas'>Mis etiquetas</h2>
          <ul>
            {elements.map((item => {
              console.log(item)
              return (
                <li key={item.id}>
                  <ElementoLista detalles={item.desc} id={item.id} onChange={changeElements}
                    cod_log={item.code} residuo={item.nombre_residuo} item={item}/>
                </li>
              )
            }))}

          </ul>
          <div className="pagination">
          {/* <Pagination.First disabled={disablePrev} onClick={goFirts} /> */}
          <Pagination.Prev disabled={disablePrev} onClick={prevEtiquetas}/>
          {pages.slice(selectedPage - 2 < 0 ? 0: selectedPage -2, selectedPage-2 + 4).map(item =>{
            logger.warn('Seleccion de pagina', selectedPage)
            if(selectedPage == item)
            return (<Pagination.Item active onClick={()=>{goEnd(item)}}>{item}</Pagination.Item>)
            return(<Pagination.Item onClick={()=>{goEnd(item)}}>{item}</Pagination.Item>)
          })}
          <Pagination.Next disabled={disableNext} onClick={nextEtiquetas}/>
          {/* <Pagination.Last disabled={disableNext} onClick={goEnd}/> */}
          </div>
        </div>
      </div>
    )
  }

}

