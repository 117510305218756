import { Logger } from "@kcram-solutions/logger";
import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join"; 
import api from "../constants/api";
import storageKeys from "../constants/storageKeys";
import { recuperar } from "../helpers/local_storage";

const logger = new Logger('simbolos-request');
export class Simbolos {
    static __list = null;
    static __last = null;
    static async list() {
        const url = urlJoin(api.host, api.base, api.simbolos); 
        try{ 
            const response = await axios.get(url, {
                headers: {
                    //"User-Agent": api.userAgent,
                    "Authorization": "Bearer " + recuperar(storageKeys.user.token)
                }
            });
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            logger.debug('Dentro de la request:', response.data);
            return response.data; 
             
        } catch(error){
            return false;
        }  
    }
    static async checkLast(){
        const lastUpdate = this.__last;
        logger.debug('Comprobamos última actualización');
        const now = new Date();
        if(!lastUpdate || !(this.__last instanceof Date) ||
        (lastUpdate.getMinutes() - now.getMinutes()) > 5 ) {
        logger.debug('Actualizamos');
            this.__list = await this.list();
            this.__last = new Date();
            return;
        }
        return;
        
    }

    static async getOne(id){
        logger.debug("ID a buscar", id);
        await this.checkLast();
        let result = null, i;
        for(i = 0, result = this.__list[i]; i < this.__list.length && (result.id != id.id);result = this.__list[i], i++);
        logger.debug('RESULTADO: ', result);
        return result;
    }
}

