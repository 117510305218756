import globals from "../constants/globals";
import storageKeys from '../constants/storageKeys'; 

/**
* Almacena los datos en el localStorage
* @param {string} key la clave que se usará para el registro
* @param {any} value el dato a guardar
*/
export function almacenar(key, value, forceLocal=false) {
	let dato_value = value; 
	if(typeof value == "object") {		
		dato_value = JSON.stringify(value); 
	} 
	const local = recuperarCheck() || forceLocal;
	if (!local) {
		sessionStorage.setItem(key, dato_value); 
	} else {
		localStorage.setItem(key, new String(dato_value));
	}
}

function recuperarCheck() {
	return localStorage.getItem(globals.recuerdame.recordar)
}

/**
 * Recupera un registro del localStorage
 * @param {string} key la clave del registro
 * @returns {any} el contenido del registro
 */
export function recuperar(key) {
	let valor;
	let dato_value;
	if (recuperarCheck()) {
		dato_value = localStorage.getItem(key);
	} else {
		dato_value = sessionStorage.getItem(key);
	}
	try {
		valor = JSON.parse(dato_value);
		return valor;
	}
	catch (e) {
	}
	try {
		valor = parseFloat(dato_value);
		if(new String(valor) == "NaN") {
			throw new Error();
		} 
		return valor;
	}
	catch (e) {
	}
	try {
		valor = parseInt(dato_value);
		if(new String(valor) == "NaN") {
			throw new Error();
		} 
		return valor;
	}
	catch (e) {
	}
	try {
		switch(dato_value) {
			case "true": 
				valor = true;
				break;
			case "false":
				valor = false;
				break;
			default: 
				throw new Error();
		} 
		return valor;
	}
	catch (e) {

	}
	return dato_value;
}