import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join"; 
import api  from "../constants/api";

export class Registro {
    static async registro(email, password, password2, empresa, direccion, telefono, eni) {
        const url = urlJoin(api.host, api.base, api.users); 
        try{ 
            //const response = await axios.post(url, {email, password, password2, empresa, direccion, telefono, eni}, {headers: {"User-Agent": api.userAgent}});
            const response = await axios.post(url, {email, password, password2, empresa, direccion, telefono, eni});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
             
        } catch(error){
            return false;
        } 
             
         
    }
}